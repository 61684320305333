<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="车辆编号" prop="vehicleId">
                <a-input v-model="queryParam.vehicleId" placeholder="请输入车辆编号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="任务名称" prop="taskName">
                <a-input v-model="queryParam.taskName" placeholder="请输入任务名称" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="开始时间">
                  <a-range-picker
                    style="width: 100%"
                    v-model="daterangeTaskStartTime"
                    valueFormat="YYYY-MM-DD"
                    format="YYYY-MM-DD"
                    allow-clear
                  />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="任务备注" prop="remark">
                  <a-input v-model="queryParam.remark" placeholder="请输入任务备注" allow-clear />
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="状态" prop="taskState">
                  <a-select placeholder="请选择状态" v-model="queryParam.taskState" style="width: 100%" allow-clear>
                    <a-select-option v-for="(d, index) in taskStateOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <!-- <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:vehicleTask:add']">
          <a-icon type="plus" />新增
        </a-button> -->
          <!-- <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['tenant:iot:vehicleTask:edit']">
          <a-icon type="edit" />修改
        </a-button> -->
          <!-- <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:vehicleTask:remove']">
          <a-icon type="delete" />删除
        </a-button> -->
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:vehicleTask:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
            type="dashed"
            shape="circle"
            :loading="loading"
            :style="{ float: 'right' }"
            icon="reload"
            @click="getList"
          /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :taskStateOptions="taskStateOptions" :statusOptions="statusOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="taskStartTime" slot-scope="text, record">
          {{ parseTime(record.taskStartTime) }}
        </span>
        <span slot="taskExpectTime" slot-scope="text, record">
          {{ parseTime(record.taskExpectTime) }}
        </span>
        <span slot="taskState" slot-scope="text, record">
          {{ taskStateFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTask:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:vehicleTask:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:vehicleTask:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:vehicleTask:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listVehicleTask, delVehicleTask, exportVehicleTask } from '@/api/iot/vehicleTask'
import CreateForm from './modules/CreateForm'

export default {
  name: 'VehicleTask',
  components: {
    CreateForm
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 开始时间时间范围
      daterangeTaskStartTime: [],
      // 任务状态(字典表设定)字典
      taskStateOptions: [],
      // 是否删除 0否1是字典
      statusOptions: [],
      // 查询参数
      queryParam: {
        vehicleId: null,
        taskName: null,
        taskStartTime: null,
        remark: null,
        taskState: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '名称',
          dataIndex: 'taskName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '发起人',
          dataIndex: 'createBy',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开始时间',
          dataIndex: 'taskStartTime',
          scopedSlots: { customRender: 'taskStartTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'taskExpectTime',
          scopedSlots: { customRender: 'taskExpectTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '路线',
          dataIndex: 'routeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '任务备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '任务进度',
          dataIndex: 'taskProgress',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'taskState',
          scopedSlots: { customRender: 'taskState' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getDicts('iot_task_status').then((response) => {
      this.taskStateOptions = response.data
    })
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询车辆任务列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeTaskStartTime !== null &&
        this.daterangeTaskStartTime !== '' &&
        this.daterangeTaskStartTime.length !== 0
      ) {
        this.queryParam.params['beginTaskStartTime'] = this.daterangeTaskStartTime[0]
        this.queryParam.params['endTaskStartTime'] = this.daterangeTaskStartTime[1]
      }
      listVehicleTask(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 任务状态(字典表设定)字典翻译
    taskStateFormat(row, column) {
      return this.selectDictLabel(this.taskStateOptions, row.taskState)
    },
    // 是否删除 0否1是字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeTaskStartTime = []
      this.queryParam = {
        vehicleId: undefined,
        taskName: undefined,
        remark: undefined,
        taskState: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delVehicleTask(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportVehicleTask(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
